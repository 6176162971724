// import {
//     req
// } from "../serve/request.js";

// const $api = req;
//isOrder判断是订单取消还是充值取消   判断页面是否跳转
const pay = (orderNo, openid, threshold, isOrder) => {
    uni.showLoading({
        title: "支付安全检查中",
        mask: true
    })
    return new Promise((resolve, reject) => {
        if (!orderNo) {
            throw new Error("orderNo must be not null");
            return;
        }
        if (!openid) {
            throw new Error("openid must be not null");
            return;
        }
        // if (!threshold) {
        //         resolve({
        //                 code: -1,
        //                 msg: "请传入充值金额"
        //         })
        //         return;
        // }

        setTimeout(() => {
            $api.WxUnifiedorder({
                orderNo: orderNo,
                // type: type,
                openid: openid,
                totalfee: threshold
                // totalfee: '0.01'
            }).then(res => {
                if (res.Code == -1) {
                    uni.showToast({
                        title: res.Msg,
                        mask: true,
                        icon: "none"
                    })
                    reject({
                        code: -1,
                        msg: "未获取到订单或者订单已支付"
                    })
                    return;
                }
                uni.hideLoading();
                uni.requestPayment({
                    provider: 'wxpay',
                    timeStamp: res.Data.timeStamp,
                    nonceStr: res.Data.nonceStr,
                    package: res.Data.package,
                    signType: 'RSA',
                    paySign: res.Data.paySign,
                    success: function (res) {
                        if (res.errMsg == 'requestPayment:ok') {
                            $api.ConfirmOrderStatus({
                                orderNo: orderNo
                            }).then(res4 => {
                                //支付成功   调这个接口会通知前台
                                // if(res4.Code==200){
                                // 	$api.SendMessageToClient({
                                // 		orderNo: orderNo
                                // 	})
                                // }
                                if (res4.Code == -1) {
                                    uni.showToast({
                                        title: res4.Msg,
                                        icon: 'none'
                                    });
                                    reject({
                                        code: -1,
                                        msg: res4.Msg,
                                    })
                                    return;
                                }
                                resolve({
                                    code: 200,
                                    msg: "支付成功",
                                    RedeemCode: res.Data
                                        ?.RedeemCode ? res.Data
                                        ?.RedeemCode : '',
                                    data: res4
                                })
                            })
                        }
                    },
                    fail: function (err) {
                        // console.log('fail:' + JSON.stringify(err), '===', err);
                        if (err.errMsg == 'requestPayment:fail cancel' && !
                            isOrder) {
                            uni.showToast({
                                title: '用户取消支付',
                                icon: 'none'
                            });
                        }
                        reject({
                            code: -1,
                            msg: "支付失败/取消支付",
                            data: err
                        })
                    }
                })
            }, 0)
        })

    })

    // console.log("我是支付 ==>", $api.WxPay());
}

module.exports = {
    pay,
}
