import Vue from 'vue'
import App from './App.vue'
import router from './router'


import Vant from 'vant';
import 'vant/lib/index.css';

import { pay } from './utils/pay.js'

import { reqLists } from "./serve/request"
import cookie from "./utils/cookie"; // cookie
import wx from "./utils/jweixin-1.6.0";
import isWx from "./utils/is_wx";


import 'lib-flexible/flexible.js'


Vue.use(Vant);

Vue.config.productionTip = false


Vue.prototype.$pay = pay;

Vue.prototype.$api = reqLists;

Vue.prototype.$cookie = cookie;

Vue.prototype.$wx = wx;  //微信jssdk

Vue.prototype.$isWx = isWx;   //判断是否在微信内



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
