

const allocation = require("../config/dev.js");

// console.log(allocation);
const contentType = {
    form: "application/x-www-form-urlencoded; charset=UTF-8",
    json: "application/json",
    multipart: "multipart/form-data",
    stream: "application/json",
    arraybuffer: "arraybuffer", // 图片buffer
};

const dev = allocation.development;
const baseURL = () => {
    if (dev) {
        return "/api/"
        return "/api//api/"   //测试  本地

    }
    return allocation.BASEURL
}
export const api = {
    // 登录
    "LoginJwt": {
        url: baseURL() + "_Account/Login",
        method: "post",
        // is_login: true,
    },
    //获取校服详情
    "GetGarmentInfoById": {
        url: baseURL() + "NoAuthority/GetGarmentInfoById",
        method: "post",
    },
    //获取学校详情
    "GetSchoolInfoById": {
        url: baseURL() + "NoAuthority/GetSchoolInfoById",
        method: "post",
    },
	//校验二维码
	"GetGetQRCodeById": {
	    url: baseURL() + "NoAuthority/GetGetQRCodeById",
	    method: "post",
	},
    //创建订单
    "CreateAppOrder": {
        url: baseURL() + "NoAuthority/CreateAppOrder",
        method: "post",
        is_login: true,
    },
    //预支付
    "WxUnifiedorder": {
        url: baseURL() + "NoAuthority/WxUnifiedorder",
        method: "post",
        is_login: true,
    },
    //更新订单状态 
    "UpdateOrderPayState": {
        url: baseURL() + "NoAuthority/UpdateOrderPayState",
        method: "post",
        is_login: true,
    },
    //查询订单状态 
    "WxOrderQuery": {
        url: baseURL() + "NoAuthority/WxOrderQuery",
        method: "get",
        is_login: true,
    },
    //获取openid  NoAuthority/GetOpenidByCode
    "GetOpenidByCode": {
        url: baseURL() + "NoAuthority/GetOpenidByCode",
        method: "post",
        is_login: true,
    },
    //JSAPI支付
    "WxJSAPI": {
        url: baseURL() + "NoAuthority/WxUnifiedorderWithJSAPI",
        method: "post",
        is_login: true,
    },
    //随机数接口
    "GetQRCodeInfoById": {
        url: baseURL() + "NoAuthority/GetQRCodeInfoById",
        method: "get",
        is_login: true,
    },
}