
import axios from 'axios';
import { Toast } from 'vant';
import cookie from "../utils/cookie"

axios.defaults.withCredentials = true;//请求时携带cookie

const apiList = require("./api").api;

let reqList = {};

const $get = (obj) => {
    let urls = `${obj.url}`; //拼接 url
    urls = obj.is_url ? obj.url : urls; //是否需要重写url 接外部的请求
    return function (data) {
        let count = 0;
        let getData = ""; //发送数据
        for (let key in data) {
            if (count == 0) {
                getData += '?' + key + "=" + data[key];
            } else {
                getData += '&' + key + "=" + data[key];
            }
            count++;
        }
        let requestData = {
            header: obj.header || "",
            url: urls + "" + getData,
            Loading: obj.is_loading,
            is_dwon: obj.is_dwon ? true : false,
            is_login: obj.is_login ? true : false,
            method: "GET",
            data: {}
        }
        return request(requestData);
    }
}

const $requests = (obj) => {
    let urls = `${obj.url}`; //拼接 url
    urls = obj.is_url ? obj.url : urls; //是否需要重写url 接外部的请求
    return function (data) {
        let requestData = {
            header: obj.header,
            url: urls,
            Loading: obj.is_loading,
            method: obj.method,
            data: data,
            is_dwon: obj.is_dwon ? true : false,
            is_login: obj.is_login ? true : false,
        }
        return request(requestData);
    }
}

for (const item in apiList) {
    if (apiList[item].method == "get") {
        reqList[`${item}`] = $get(apiList[item]);
    } else {
        reqList[`${item}`] = $requests(apiList[item])
    }
}
// 请求
const request = (data) => {
    if (data.Loading) {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
    }

    // 校验登录开始
    let userInfo = cookie.readCookie("userInfo");
    let userId = "";
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        userId = userInfo.id;
    }

    if ((data.method != 'get' || data.method != 'GET') && data.is_login && userId) {
        let idx = data.url.indexOf("?");
        data.url += idx == -1 ? `?userId=${userId}` : `&userId=${userId}`
    }
    if (data.is_login && userId && data.method != 'get' && data.method != 'GET') {
        data.data = data.data || {};
        data.data.userId = userId;
    }
    // 检验登录结束

    return new Promise((resolve, reject) => {
        axios({
            headers: { "content-type": data.header ? data.header : "application/json; charset=utf-8" },
            url: data.url,
            method: data.method,
            data: data.data,
            responseType: data.is_dwon ? "arraybuffer" : "json"
        }).then((res) => {
            data.Loading ? Toast.clear() : "";
            // if (res.data.code == 401) {
            //     Toast('未登陆或者登陆过期,请重新登录');
            //     cookie.delCookie("userInfo");
            //     cookie.delCookie(".AspNetCore.Cookies");
            //     setTimeout(() => {
            //         window.location.href = "/login"
            //     }, 500)
            // } else if (res.status != 200 && res.status != 304) {
            //     Toast.fail(res.message || '网络或请求错误');
            //     reject({
            //         code: -1,
            //         msg: "网络或请求错误"
            //     });
            //     return;
            // }
            // if (!res.data.success) {
            //     Toast.fail(res.data.message || '网络或请求错误');
            //     reject({
            //         code: -1,
            //         msg: "网络或请求错误",
            //         err: res.data
            //     });
            //     return;
            // }

            resolve(res.data);

        }).catch(err => {
            // data.Loading ? Toast.clear() : "";
            // Toast.fail('服务器有点小问题刷新试一试');
            // reject({
            //     code: -2,
            //     msg: "服务器问题",
            //     data: err
            // });
        })
    })
}
export const reqLists = reqList;