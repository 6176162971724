let baseUrl = [
  {
    url: 'https://zxjxzy.com/api/',
    // url: "https://tc.yunweicn.com/api/",
    // url: "http://192.168.23.142:8099/",
    desc: '正式',
    // loginUrl: "http://192.168.26.222:6343/api/_Account/Login",
    is_defaut: true,
  },

  {
    url: 'http://cqzyfz.cn/testapi/',
    desc: '线上测试空间',
    loginUrl: 'http://cqzyfz.cn/testh5/',
    is_defaut: false,
  },
]

module.exports = {
  // 基础配置
  BASEURL: baseUrl.filter((item) => item.is_defaut)[0].url,
  // 登录url
  LOGINURL: baseUrl.filter((item) => item.is_defaut)[0].loginUrl,
  // 是否为开发模式
  development: process.env.NODE_ENV == 'development',
  // 端口号
  PORT: 8059,
  // 图片公共地址 搭配 BASEURL使用
  IMGURL: 'sysFileInfo/preview',
  // 是否为测试环境
  ISTEST:
    baseUrl.filter((item) => item.is_defaut)[0].loginUrl ==
    'https://tc.yunweicn.com/testh5/',
}
